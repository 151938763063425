<template>
    <div class="revenue-detail">
        <div class="pc-content">
            <div v-if="!ifPublic" style="display:inline-block;width:392px;height:200px;position:absolute;left:50%;margin-left:-196px;top:50%;margin-top:-100px;">
                <div style="color:#000;font-size:20px;text-align:center;">密码访问数据报表</div>
                <a-input v-model="proPwd" style="height:48px;margin:32px 0 16px" placeholder="请输入6位数字密码" />
                <span @click="testPwd" style="background:rgba(24, 144, 255, 1);display:inline-block;width:100%;line-height:48px;border-radius:6px;color:#fff;text-align:center;font-size:14px;cursor: pointer;">验证访问密码</span>
            </div>
            <div v-if="ifPublic"  style="padding:24px;">
                <div style="position: fixed;width:100%;height:56px;background:#fff;line-height: 56px;left:0;top:0;z-index: 10;">
                    <span style="color:rgba(51, 51, 51, 1);font-size:18px;font-weight: bold;padding-left:24px;">{{basicData.taskName}}</span>
                    <div style="float:right;padding-right:24px;">
                        <a-icon style="color:rgba(121, 136, 155, 1);font-size:14px;margin-right:4px;" type="bank" />
                        <span style="color:rgba(121, 136, 155, 1);font-size:14px;">{{basicData.enterpriseName}}</span>
                    </div>
                </div>
                <div style="background:#fff;border-radius: 4px;padding:20px 24px;margin-top:52px;">
                    <div style="color:rgba(18, 18, 18, 1);font-size:16px;">数据提交</div>
                    <a-textarea
                        v-model="dataValue"
                        placeholder="说明：
1.最大支持10000行；
2.一行数据一个号码；
3.自动提取11位手机号码；
4.提交号码自动去重；
5.提交后不支持撤回"
                        style="margin-top:20px;"
                        :auto-size="{ minRows: 8, maxRows: 15 }"
                    />
                    <div style="margin-top:20px;">
                        <span @click="submitData" style="display: inline-block;width:72px;height: 36px;line-height: 36px;text-align: center;background:rgba(24, 144, 255, 1);border-radius: 4px;color:#fff;background:rgba(24, 144, 255, 1);cursor:pointer;">提交</span>
                    </div>
                </div>
                <div class="revenue-content">
                    <div style="margin-bottom:20px;">
                        <span style="color:rgba(18, 18, 18, 1);font-size:16px;">提交日志（仅展示最近30天记录）</span>
                    </div>
                    <a-table 
                        bordered 
                        :data-source="userMonthBillList" 
                        :pagination='false'
                        :rowKey="record => record.id"
                    >
                        <!-- <a-table-column key="id" title="提交编号" data-index="id">
                            <template slot-scope="text, record">
                                <div>{{record.id}}</div>
                            </template>
                        </a-table-column> -->
                        <a-table-column key="commitTime" title="提交时间" data-index="commitTime">
                            <template slot-scope="text, record">
                                <div>{{record.commitTime}}</div>
                            </template>
                        </a-table-column>
                        <a-table-column key="totalCount" title="提交号码数量" data-index="totalCount">
                            <template slot-scope="text, record">
                                <div>{{record.totalCount}}</div>
                            </template>
                        </a-table-column>
                        <a-table-column key="waitingCallCount" title="待呼号码数量" data-index="waitingCallCount">
                            <template slot-scope="text, record">
                                <div>{{record.waitingCallCount}}</div>
                            </template>
                        </a-table-column>
                        <a-table-column key="status" title="状态" data-index="status">
                            <template slot-scope="text, record">
                                <div v-if="record.status==1">呼叫中</div>
                                <div v-if="record.status==2">话单生成中</div>
                                <div v-if="record.status==3">已结束</div>
                                <div v-if="record.status==4">话单生成失败</div>
                            </template>
                        </a-table-column>
                        <a-table-column key="remark" title="备注" data-index="remark">
                            <template slot-scope="text, record">
                                <div>{{record.remark}}</div>
                            </template>
                        </a-table-column>
                        <a-table-column key="downloadUrl" title="话单下载" data-index="downloadUrl">
                            <template slot-scope="text, record">
                                <div>
                                    <span @click="querySite(record.id)" style="color:rgba(24, 144, 255, 1);margin-right:24px;cursor:pointer;">复制地址</span>
                                    <span v-if="record.status==3" @click="viewBillDetail(record.downloadUrl)" style="cursor:pointer;color:rgba(24, 144, 255, 1);margin-right:24px;">下载</span>
                                    <span v-if="record.status==1||record.status==2" style="color:rgba(158, 172, 196, 1);margin-right:24px;">{{ record.useTimes }}</span>
                                </div>
                            </template>
                        </a-table-column>
                    </a-table>
                </div>
            </div>
        </div>
        <a-modal title="数据提交确认" :centered='true' :maskClosable='false' v-model.trim="submitModal" footer='' width='480px'>
            <a-divider class="divider2" type="horizontal" style="margin-top:0px;margin-bottom:10px;" />
            <div class="delete-modal modal-public">
                <div class="sure-off-bind">
                    <a-icon type="warning" />
                    <span>你确认要提交{{dataList.length}}条数据吗？</span>
                </div>
                <div class="tip">可选填20字以内备注内容</div>
                <div style="margin-top:12px;">
                    <a-input v-model.trim="remark" placeholder="请输入备注内容" />
                </div>
                <a-divider class="divider2" type="horizontal" />
                <div class="btn-group2">
                    <a-button ghost @click="cancelSubmit">取消</a-button>
                    <a-button type="primary" @click="sureSubmit">确认</a-button>
                </div>
            </div>
        </a-modal>
        <textarea id="copyHtml1"></textarea>
    </div>
</template>

<script>
    export default {
        name: 'BillOverview',
        data() {
            return {
                month: '',
                basicData: '',
                ifPublic: false,
                billDetailToken: '',
                proPwd: '',
                phoneLogin: false,
                userMonthBillList: [],
                dataValue: '',
                submitModal: false,
                remark: '',
                dataList: [],
                downloadUrl: '',
                intervalId: null,
            }   
        },
        methods: {
            queryBasicData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/waitingCall/external/baseInfo', 
                        params: {
                            externalToken: this.billDetailToken,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.basicData = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryShareType() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/waitingCall/external/shareType', 
                        params: {
                            externalToken: this.billDetailToken,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        if(response.data.data.type == 1){
                            this.ifPublic = true
                            this.startFetching();
                            this.queryBasicData()
                            
                        }else{
                            this.ifPublic = false
                        }
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            queryTableData() {
                this.axios(
                    {
                        method:'get',
                        url:'/api/waitingCall/external/commitList', 
                        params: {
                            externalToken: this.billDetailToken,
                        }
                    }
                )
                .then(response=> {
                    if(response.data.code == 0){
                        this.userMonthBillList = response.data.data
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            testPwd() {
                this.axios.post('/api/waitingCall/external/valid', {
                    externalToken: this.billDetailToken,
                    password: this.proPwd
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.startFetching();
                        this.ifPublic = true
                        this.queryBasicData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },

            viewBillDetail(url) {
                // this.$router.push({path:"/BillOverview",query:{month:key}})
                window.open(url);
                
            },
            submitData() {
                if(this.dataValue!=''){
                    this.submitModal = true
                    console.log(this.dataValue)
                    this.dataList = this.dataValue.split('\n');
                }
            },
            cancelSubmit() {
                this.submitModal = false
            },
            sureSubmit() {
                this.submitModal = false
                this.axios.post('/api/waitingCall/external/import', {
                    externalToken: this.billDetailToken,
                    remark: this.remark,
                    phoneList: this.dataList
                })
                .then(response=> {
                    if(response.data.code == 0){
                        this.dataValue = ''
                        this.remark = ''
                        this.queryTableData()
                    }else{
                        this.$message.error(response.data.userTip);
                    }
                })
                .catch(error=> {
                    console.log(error)
                })
            },
            querySite(id) {
                // this.axios(
                //     {
                //         method:'get',
                //         url:'/api/waitingCall/external/downloadUrl', 
                //         params: {
                //             id: id,
                //         }
                //     }
                // )
                // .then(response=> {
                //     if(response.data.code == 0){
                //         this.downloadUrl = response.data.data.callRecordUrl
                //         let text = this.downloadUrl
                //         let input = document.getElementById("copyHtml1");
                //         input.value = text; 
                //         input.select(); 
                //         document.execCommand("copy"); 
                //         this.$message.success('复制成功');
                //     }else{
                //         this.$message.error(response.data.userTip);
                //     }
                // })
                // .catch(error=> {
                //     console.log(error)
                // })
                    this.downloadUrl = 'https://eocc.ixiaowen.net/#/DownloadFile?id='+id
                    let text = this.downloadUrl
                    let input = document.getElementById("copyHtml1");
                    input.value = text; 
                    input.select(); 
                    document.execCommand("copy"); 
                    this.$message.success('复制成功');
            },
            startFetching() {
                this.queryTableData(); // 立即请求一次数据
                this.intervalId = setInterval(
                    this.queryTableData, 
                20000); // 每 5 秒请求一次
            },
            stopFetching() {
                clearInterval(this.queryTableData); // 清除定时器
            },
        },
        mounted: function() {
            this.billDetailToken = this.$route.query.billDetailToken
            this.queryShareType()
        },
        beforeDestroy() {
            this.stopFetching(); // 组件销毁前清除定时器
        },
    }
</script>

<style scoped lang="less">
    .revenue-detail{
        height: 100%;
        .pc-content{
            background: rgba(250, 250, 250, 1);
            .revenue-title{
                height: 64px;
                padding: 14px 35px;
                background: #fff;
                box-shadow: 0px 2px 8px 0px rgba(153, 155, 157, 0.08);
                z-index: 10;
                position: fixed;
                width: 100%;
            }
            .revenue-content{
                background: #fff;
                padding: 24px;
                margin-top: 20px;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
                .row-item{
                    margin-top: 16px;
                    .col-item{
                        span:nth-child(1){
                            color: rgba(169, 169, 169, 1);
                            font-size: 12px;
                            display: inline-block;
                            width: 96px;
                        }
                        span:nth-child(2){
                            color: rgba(0, 0, 0, 1);
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        .quality-login{
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            background: #fff;
            .login-box{
                width: 392px;
                height: 180px;
                display: inline-block;
                position: absolute;
                left: 50%;
                margin-left: -196px;
                top: 50%;
                margin-top: -90px;
            }
            .phone-box{
                position: relative;
                background: rgba(4, 17, 26, 1); 
                padding: 160px 24px 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    .delete-modal{
        padding-top: 24px;
        padding-bottom: 48px;
        .sure-off-bind{
            i{
                color: rgba(255, 170, 0, 1);
                font-size: 24px;
            }
            span{
                color: rgba(18, 18, 18, 1);
                font-size: 20px;
                margin-left: 24px;
            }
        }
        .tip{
            color: rgba(80, 80, 80, 1);
            padding-left: 0px;
            margin: 34px 0 12px;
        }
    }
    .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .divider3{
            margin: 0px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
    #copyHtml1{
        position: fixed;
        z-index: -10000;
        left: 1000px;
        top: 1000000px;
    }
</style>