<template>
    <div class="comment">
        <div class="title" style="margin-bottom:8px;">评论</div>
        <div v-if="ifComment" class="textarea-comment" id='comment-textarea1' contenteditable=true ref="menuItem1">
                
        </div>
        <div v-if="ifComment" class="release">
            <span @click="showUserListBox()" class="symbol-icon">@</span>
            <div class="selected-user">
                <div v-for="(item, index) in selectedUserList" :key='index'>
                    <span>{{item.name}}</span>
                    <a-icon @click="removeUserList(index)" type="close" />
                </div>
            </div>
            <div class="cancel-submit">
                <span @click="cancelFatherComment()">取消</span>
                <span @click="submitComment()">发布</span>
            </div>
            <div contenteditable=false v-if="userBool" class="user-list1">
                <ul>
                    <li @click="chooseUser1(item1)" v-for="(item1,index1) in userList" :value="item1.value" :key='index1'>{{item1.name}}</li>
                </ul>
            </div>
        </div>
        <div class="comment-content">
            <div class="comment-list" v-for="(item,index) in treeData" :key='index'>
                <img v-if="item.avatar!=''&&item.avatar!=null" :src="item.avatar" alt="">
                <span class="username" v-if="item.commentBy!=null&&item.commentBy!=''&&(item.avatar==''||item.avatar==null)">
                    {{(item.commentBy).substr(0, 1)}}
                </span>
                <span style="font-size:12px" v-if="item.commentBy==null||item.commentBy==''">
                    已删用户
                </span>
                <div class="responce-content">
                    <div class="name">
                        <span>{{item.commentBy}}</span>
                        <span>{{item.createTime}}</span>
                    </div>
                    <div class="response-text" v-html="item.content"></div>
                    <div v-if="ifComment&&!boolList[index]&&item.type!=2" class="comment-operate">
                        <span @click="doCommit(item.id,index)">评论</span>
                    </div>
                    <div v-if="boolList[index]" style="position:relative">
                        <div class="textarea-comment content-comment" id='comment-textarea2' contenteditable=true ref="menuItem2">
                
                        </div>
                        <div class="son-submit-comment">
                            <span @click="showSonUserBox()" class="symbol-icon">@</span>
                            <div class="selected-user">
                                <div v-for="(item1, index1) in commentTreeBool[index]['selected']" :key='index1'>
                                    <span>{{item1.name}}</span>
                                    <a-icon @click="removeSonUserList(index,index1)" type="close" />
                                </div>
                            </div>
                            <div class="cancel-submit">
                                <span @click="cancelSonComment(index)">取消</span>
                                <span @click="submitSonComment()">发布</span>
                            </div>
                            <div contenteditable=false v-if="userSonBool" class="user-list1">
                                <ul>
                                    <li @click="chooseUser2(index,item2)" v-for="(item2,index2) in userList" :value="item2.value" :key='index2'>{{item2.name}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="response-to" v-for="(item1,index1) in item.children" :key='index1'>
                        <span class="name1">{{item1.commentBy}}</span>
                        <span class="res">回复</span>
                        <span class="name2">{{item1.replyTo}}：</span>
                        <span class="res-text" v-html="item1.content"></span>
                        <span v-if="!ifPhone" class="time">{{item1.createTime}}</span>
                    </div>
                    <div class="quote" v-if="item.type==2&&item.quote!=''">
                        {{item.quote}}
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:[],
    data(){
        return{
            treeData: [],
            commitContent: '',
            moduleId: '',
            dataId: '',
            replyCommentId: undefined,
            responseComment: '',
            ifShowUser1: false,
            ifShowUser2: false,
            userList: [],
            boolList: [],
            ifComment: undefined,
            ifPhone: false,
            selectedUserList: [],
            userBool: false,
            commentTreeBool: [],
            userSonBool: false,
        }
    },
    methods: {
        linkUser1(e){
            if(50 == e.keyCode && e.shiftKey){
                this.ifShowUser1 = true
            }
        },
        linkUser2(e){
            if(50 == e.keyCode && e.shiftKey){
                this.ifShowUser2 = true
            }
        },
        getParentCommentTree(moduleId,dataId,bool) {
            this.moduleId = moduleId
            this.dataId = dataId
            this.ifComment = bool
            if(bool){
                this.queryUserList()
            }
            this.queryTreeData()
        },
        chooseUser1(item) {
            this.userBool = false
            event.stopPropagation(); 
            let flag = true
            for(let i = 0; i < this.selectedUserList.length; i++){
                if(this.selectedUserList[i]['value'] == item.value){
                    flag = false
                }
            }
            if(flag){
                this.selectedUserList.push(item)
            }
            this.$forceUpdate()
        },
        chooseUser2(index, item1) {
            // var container = document.getElementById("comment-textarea2");
            // container.innerHTML = (container.innerHTML).substr(0, container.innerHTML.length - 1) + '<span class="comment-user2" value='+item.value+' style="color:#1890FF" contenteditable=false>@'+item.name+'</span>';
            // this.ifShowUser2 = false
            // this.boolList[index] = false
            event.stopPropagation(); 
            let flag = true
            this.userSonBool = false
            for(let i = 0; i < this.commentTreeBool[index]['selected'].length; i++){
                if(this.commentTreeBool[index]['selected'][i]['value'] == item1.value){
                    flag = false
                }
            }
            if(flag){
                this.commentTreeBool[index]['selected'].push(item1)
            }
            this.$forceUpdate()
        },
        submitCommit(isRoot) {
            let arr = [], getMenuText = ''
            if(isRoot == 1){
                getMenuText = this.$refs.menuItem1.innerText;
                let aa = document.querySelectorAll('.comment-user1')
                for(let i = 0; i < aa.length; i++){
                    arr.push(aa[i].attributes.value.value)
                }
            }else{
                getMenuText = this.$refs.menuItem2[0].innerText;
                let aa = document.querySelectorAll('.comment-user2')
                for(let i = 0; i < aa.length; i++){
                    arr.push(aa[i].attributes.value.value)
                }
            }
            this.axios.post('/api/comment', {
                moduleId: this.moduleId,
                dataId: this.dataId,
                content: getMenuText,
                isRoot: isRoot,
                replyCommentId: isRoot==1?undefined:this.replyCommentId,
                designeeList: arr
            })
            .then(response=> {
                if(response.data.code == 0){
                    document.getElementById("comment-textarea1").innerHTML = ''
                    this.queryTreeData()
                }else{
                    this.$message.error(response.data.userTip);
                        if(response.data.code == 'A0200'){
                            this.$router.push("/login")
                        }
                }
            })
            .catch(error=> {
                console.log(error);
            });
        },
        doCommit(id, index) {
            
            this.replyCommentId = id
            for(let i = 0; i < this.boolList.length; i++){
                if(i == index){
                    this.boolList[index] = true
                }else{
                    this.boolList[i] = false
                }
            }
            this.$forceUpdate()
        },
        offCommit() {
            for(let i = 0; i < this.boolList.length; i++){
                this.boolList[i] = false
            }
            this.$forceUpdate()
        },
        queryTreeData() {
            this.axios(
                {
                    method:'get',
                    url:'/api/comment/tree', 
                    params: {
                        moduleId: this.moduleId,
                        dataId: this.dataId
                    }
                }
            )
            .then(response=> {
                if(response.data.code == 0){
                    this.treeData = response.data.data
                    this.boolList = []
                    this.commentTreeBool = []
                    for(let i = 0; i < this.treeData.length; i++){
                        this.boolList.push(false)
                        let obj = {}
                        obj.selected = []
                        obj.userListBoxBool = false
                        this.commentTreeBool.push(obj)
                    }
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error)
            })
        },
        queryUserList() {
            this.axios(
                {
                    method:'get',
                    url:'/api/user/list', 
                }
            )
            .then(response=> {
                if(response.data.code == 0){
                    this.userList = response.data.data
                }else{
                    this.$message.error(response.data.userTip);
                    if(response.data.code == 'A0200'){
                        this.$router.push("/login")
                    }
                }
            })
            .catch(error=> {
                console.log(error)
            })
        },
        showUserListBox() {
            event.stopPropagation(); 
            this.userBool = true
            this.$forceUpdate()
        },
        removeUserList(index) {
            this.selectedUserList.splice(index,1)
            this.$forceUpdate()
        },
        cancelFatherComment() {
            this.selectedUserList = []
            document.getElementById("comment-textarea1").innerHTML = ''
        },
        submitComment() {
            let content = ''
            let container = document.getElementsByClassName("textarea-comment");
            content = container[0].innerText
            let arr = []
            for(let i = 0; i < this.selectedUserList.length; i++){
                arr.push(this.selectedUserList[i]['value'])
            }
            this.axios.post('/api/comment', {
                moduleId: this.moduleId,
                type: 1,
                dataId: this.dataId,
                content: content,
                isRoot: 1,
                replyCommentId: undefined,
                designeeList: arr
            })
            .then(response=> {
                if(response.data.code == 0){
                    this.selectedUserList = []
                    document.getElementById("comment-textarea1").innerText = ''
                    this.queryTreeData()
                }else{
                    this.$message.error(response.data.userTip);
                }
            })
            .catch(error=> {
                console.log(error);
            });
        },
        showSonUserBox() {
            event.stopPropagation(); 
            this.userSonBool = true
        },
        removeSonUserList(index, index1) {
            this.commentTreeBool[index]['selected'].splice(index1,1)
            this.$forceUpdate()
        },
        cancelSonComment(index) {
            this.commentTreeBool[index]['selected'] = []
            document.getElementById("comment-textarea2").innerText = ''
            
        },
        submitSonComment() {
            let content = document.getElementById("comment-textarea2").innerText;
            let arr = []
            for(let i = 0; i < this.commentTreeBool.length; i++){
                for(let j = 0; j < this.commentTreeBool[i]['selected'].length; j++){
                    arr.push(this.commentTreeBool[i]['selected'][j]['value'])
                }
            }
            this.axios.post('/api/comment', {
                moduleId: this.moduleId,
                type: 1,
                dataId: this.dataId,
                content: content,
                isRoot: 0,
                replyCommentId: this.replyCommentId,
                designeeList: arr
            })
            .then(response=> {
                if(response.data.code == 0){
                    this.selectedUserList = []
                    document.getElementById("comment-textarea1").innerText = ''
                    this.queryTreeData()
                }else{
                    this.$message.error(response.data.userTip);
                }
            })
            .catch(error=> {
                console.log(error);
            });
        }
    },
    mounted: function() {
        document.onclick = ()=> {
            this.ifShowUser1 = false
            this.ifShowUser2 = false
            this.userBool = false
            this.userSonBool = false
            // for(let i = 0; i < this.boolList.length; i++){
            //     this.boolList[i] = false
            // }
        }
        let screenWidth = document.body.clientWidth
        if(screenWidth < 900){
            this.ifPhone = true
        }
        window.onresize = () => {
            screenWidth = document.body.clientWidth
            if(screenWidth < 900){
                this.ifPhone = true
            }else{
                this.ifPhone = false
            }
        }
    }
}
</script>
<style scoped lang="less">
    .comment{
        .comment-body{
            position: relative;
        }
        .textarea-comment{
            margin-top: 20px;
            background: rgba(247, 247, 247, 1);
            border: none;
            resize: none;
            height: 71px;
            padding: 5px;
            position: relative;
        }
        
        .user-list2{
            display: inline-block;
            border: 1px solid #ccc;
            width:100px;
            height: 200px;
            overflow-x: hidden;
            overflow-y: auto;
            border-radius: 4px;
            position: absolute;
            left: 0px;
            top: 75px;
            z-index: 100;
            background: #fff;
            ul{
                li{
                    line-height: 25px;
                    cursor: pointer;
                    padding-left: 8px;
                }
                li:hover{
                    background: #eee;
                }
            }
        }
        .release{
            margin-top: 4px;
            position: relative;
            .symbol-icon{
                font-size: 14px;
                cursor: pointer;
                color: #1890ff
            }
            .cancel-submit{
                float: right;
                span{
                    font-size: 14px;
                    margin-left: 16px;
                    cursor: pointer;
                }
                span:nth-child(1){
                    color: rgba(158, 172, 196, 1);
                }
                span:nth-child(2){
                    color: rgba(24, 144, 255, 1);
                }
            }
            .selected-user{
                display: inline-block;
                div{
                    display: inline-block;
                    height: 24px;
                    padding: 0 8px;
                    line-height: 24px;
                    color: rgba(80, 80, 80, 1);
                    font-size: 12px;
                    background: rgba(121, 136, 155, 0.1);
                    margin-left: 4px;
                    i{
                        color: rgba(153, 153, 153, 1);
                        cursor: pointer;
                        margin-left: 8px;
                    }
                }
            }
            .user-list1{
                display: inline-block;
                border: 1px solid #ccc;
                width:100px;
                height: 200px;
                overflow-x: hidden;
                overflow-y: auto;
                border-radius: 4px;
                position: absolute;
                left: 0px;
                top: 23px;
                z-index: 100;
                background: #fff;
                ul{
                    li{
                        line-height: 25px;
                        cursor: pointer;
                        padding-left: 8px;
                    }
                    li:hover{
                        background: #eee;
                    }
                }
            }
        }
        .comment-content{
            margin-top: 8px;
            .comment-list{
                display: flex;
                margin-bottom: 16px;
                img{
                    width: 40px;
                    height: 40px;
                    margin-right: 16px;
                    border-radius: 20px;
                }
                .responce-content{
                    border-bottom: 1px solid rgba(229, 229, 229, 1);
                    padding-bottom: 16px;
                    flex: 1;
                    .name{
                        color: rgba(153, 153, 153, 1);
                        font-size: 12px;
                        span{
                            margin-right:10px;
                        }
                        margin-bottom: 8px;
                    }
                    .response-text{
                        color: rgba(80, 80, 80, 1);
                        font-size: 14px;
                        margin-bottom: 14px;
                    }
                    .son-submit-comment{
                        position: relative;
                        margin-top: 4px;
                        .symbol-icon{
                            font-size: 14px;
                            cursor: pointer;
                            color: #1890ff
                        }
                        .cancel-submit{
                            float: right;
                            span{
                                font-size: 14px;
                                margin-left: 16px;
                                cursor: pointer;
                            }
                            span:nth-child(1){
                                color: rgba(158, 172, 196, 1);
                            }
                            span:nth-child(2){
                                color: rgba(24, 144, 255, 1);
                            }
                        }
                        .selected-user{
                            display: inline-block;
                            div{
                                display: inline-block;
                                height: 24px;
                                padding: 0 8px;
                                line-height: 24px;
                                color: rgba(80, 80, 80, 1);
                                font-size: 12px;
                                background: rgba(121, 136, 155, 0.1);
                                margin-left: 4px;
                                i{
                                    color: rgba(153, 153, 153, 1);
                                    cursor: pointer;
                                    margin-left: 8px;
                                }
                            }
                        }
                        .user-list1{
                            display: inline-block;
                            border: 1px solid #ccc;
                            width:100px;
                            height: 200px;
                            overflow-x: hidden;
                            overflow-y: auto;
                            border-radius: 4px;
                            position: absolute;
                            left: 0px;
                            top: 23px;
                            z-index: 100;
                            background: #fff;
                            ul{
                                li{
                                    line-height: 25px;
                                    cursor: pointer;
                                    padding-left: 8px;
                                }
                                li:hover{
                                    background: #eee;
                                }
                            }
                        }
                    }
                    .comment-operate{
                        span{
                            cursor: pointer;
                            color: rgba(121, 136, 155, 1);
                            font-size: 12px;
                        }
                    }
                    .response-to{
                        margin-top: 8px;
                        padding: 8px 16px;
                        background: rgba(247, 247, 247, 1);
                        .name1{
                            color: rgba(121, 136, 155, 1);
                            font-size: 12px;
                        }
                        .name2{
                            color: rgba(121, 136, 155, 1);
                            font-size: 12px;
                        }
                        .res{
                            color: rgba(80, 80, 80, 1);
                        }
                        .res-text{
                            color: rgba(80, 80, 80, 1);
                            font-size: 12px;
                        }
                        .time{
                            color: rgba(153, 153, 153, 1);
                            font-size: 12px;
                            float: right;
                        }
                    }
                    .quote{
                        padding: 6px 16px;
                        background: rgba(247, 247, 247, 1);
                        color: rgba(121, 136, 155, 1);
                        font-size: 14px;
                        margin-top: 6px;
                    }
                }
                .username{
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                    color: #fff;
                    text-align: center;
                    line-height: 40px;
                    background: rgba(121, 72, 234, 0.6);
                    font-size: 20px;
                    float: left;
                }
            }
        }
    }
    .time-modal{
        padding-bottom: 46px;
        .check-list{
            .check-item{
                display: inline-block;
                width: 160px;
                height: 32px;
            }
        }
    }
      .modal-public{
        .divider1{
            margin: 0px 0 24px 0;
        }
        .divider2{
            margin: 24px 0 12px;
        }
        .btn-group{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                margin-right: 16px;
            }
        }
        .btn-group2{
            float: right;
            button{
                width: 64px;
                height: 32px;
                font-size: 14px;
            }
            button:nth-child(1){
                background: #fff;
                color: rgba(121, 136, 155, 0.7);
                border: 1px solid rgba(121, 136, 155, 0.5);
                margin-right: 16px;
            }
            button:nth-child(1):hover{
                background: rgba(121, 136, 155, 0.08);
            }
        }
    }
</style>